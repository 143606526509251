'use client';
import Image from 'next/image';
import Link from 'next/link';

import Carousel from 'src/components/Carousel';
import { Announcement } from 'src/services/keystone-types';

type Props = {
    items: Announcement[];
};

const CarouselItem = ({
    title,
    multipleItems = false,
    readMoreLink,
}: {
    title: string;
    multipleItems?: boolean;
    readMoreLink?: string;
}) => (
    <div className={`m-4 bg-white p-4 md:m-8 md:mx-0 ${multipleItems ? 'mt-12 md:mb-12' : ''}`}>
        <span className="mr-4">{title}</span>
        {!!readMoreLink && (
            <Link className="btn btn-primary inline-block" href={readMoreLink}>
                Citește mai mult
            </Link>
        )}
    </div>
);

export default function AnnouncementsSection({ items }: Props) {
    if (!items.length) return null;

    return (
        <div className="relative">
            <Image
                src="/images/homepage-hospital.jpg"
                alt="Anunțuri"
                className="absolute -z-10 object-cover"
                fill
                quality={75}
                sizes="100vw"
            />
            <div className={`text-primary container mx-auto ${items.length > 0 ? 'py-2' : ''}`}>
                {items.length > 1 ? (
                    <Carousel
                        items={items}
                        keyForItem={(item) => item.title}
                        contentForItem={(item) => (
                            <CarouselItem title={item.title} multipleItems={true} readMoreLink={item.read_more_link} />
                        )}
                    />
                ) : (
                    <CarouselItem title={items[0].title} />
                )}
            </div>
        </div>
    );
}
